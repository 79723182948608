import React, { useCallback, useEffect, useRef, useState } from "react";
import { EntityExplorerResizeHandler } from "pages/Editor/Explorer/Common/EntityExplorerResizeHandler";
import {
  getCurrentPackage,
  getCurrentPackageId,
} from "ee/selectors/packageSelectors";
import { useSelector } from "react-redux";
import { RelativeContainer } from "pages/Editor/Explorer/Common/components";
import { getExplorerStatus, saveExplorerStatus } from "../../Explorer/helpers";
import {
  ADD_MODULE_TOOLTIP,
  MODULES_TITLE,
  createMessage,
} from "ee/constants/messages";
import { hasCreateModulePermission } from "ee/utils/permissionHelpers";
import EntityAddButton from "pages/Editor/Explorer/Entity/AddButton";
import CreateNewModuleMenu from "./CreateNewModuleMenu";
import ModuleEntities from "./ModuleEntities";
import Entity from "pages/Editor/Explorer/Entity";

interface ModulesProps {
  showDivider?: boolean;
}

const Modules = ({ showDivider = true }: ModulesProps) => {
  const packageId = useSelector(getCurrentPackageId) || "";
  const isModulesOpen = getExplorerStatus(packageId, "packages");
  const moduleResizeRef = useRef<HTMLDivElement>(null);
  const storedHeightKey = "modulesContainerHeight_" + packageId;
  const storedHeight = localStorage.getItem(storedHeightKey);
  const userPackagePermissions =
    useSelector(getCurrentPackage)?.userPermissions ?? [];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onModuleToggle = useCallback(
    (isOpen: boolean) => {
      saveExplorerStatus(packageId, "packages", isOpen);
    },
    [packageId],
  );

  useEffect(() => {
    if (
      (isModulesOpen === null ? true : isModulesOpen) &&
      moduleResizeRef.current
    ) {
      moduleResizeRef.current.style.height = storedHeight + "px";
    }
  }, [moduleResizeRef]);

  const canCreateModules = hasCreateModulePermission(userPackagePermissions);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <RelativeContainer
      className={`${showDivider ? "border-b" : ""} pb-1 h-full`}
    >
      <Entity
        addButtonHelptext={createMessage(ADD_MODULE_TOOLTIP)}
        alwaysShowRightIcon
        className="pb-0 group modules"
        collapseRef={moduleResizeRef}
        customAddButton={
          <CreateNewModuleMenu
            canCreate={canCreateModules}
            closeMenu={closeMenu}
            isOpen={isMenuOpen}
            triggerElement={
              <EntityAddButton
                className="create-module-btn"
                onClick={openMenu}
              />
            }
          />
        }
        entityId="modules"
        icon={""}
        isDefaultExpanded
        isSticky
        name={createMessage(MODULES_TITLE)}
        onToggle={onModuleToggle}
        searchKeyword={""}
        showAddButton={canCreateModules}
        step={0}
      >
        <ModuleEntities
          canCreateModules={canCreateModules}
          openCreateNewMenu={openMenu}
          packageId={packageId}
        />
      </Entity>

      {showDivider && (
        <EntityExplorerResizeHandler
          resizeRef={moduleResizeRef}
          storedHeightKey={storedHeightKey}
        />
      )}
    </RelativeContainer>
  );
};

export default Modules;
