import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import Editor from "pages/Editor/APIEditor/Editor";
import { getActionByBaseId, getPlugins } from "ee/selectors/entitiesSelector";
import { get } from "lodash";
import { ApiEditorContextProvider } from "pages/Editor/APIEditor/ApiEditorContext";
import { getIsWorkflowEditorInitialized } from "ee/selectors/workflowSelectors";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import type { PaginationField } from "api/ActionAPI";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHasDeleteActionPermission } from "ee/utils/BusinessFeatures/permissionPageHelpers";
import { deleteAction, runAction } from "actions/pluginActionActions";
import ActionEditorContextMenu from "../ModuleEditor/ActionEditorContextMenu";
import { saveWorkflowActionName } from "ee/actions/workflowActions";
import type { PluginType } from "entities/Action";
import { useWorkflowQuerySettings } from "ee/hooks/workflowHooks";
import { WorkflowPluginActionEditor } from "ee/pages/Editor/WorkflowEditor/WorkflowPluginActionEditor";

interface WorkflowApiEditorRouteParams {
  workflowId: string;
  baseApiId?: string;
}

type WorkflowApiEditorProps = RouteComponentProps<WorkflowApiEditorRouteParams>;

// TODO (Workflows): Remove pageId as dependency of this page, needs API refactor

function WorkflowApiEditor(props: WorkflowApiEditorProps) {
  const { baseApiId = "" } = props.match.params;
  const action = useSelector((state) => getActionByBaseId(state, baseApiId));
  const apiId = action?.id || "";
  const apiName = action?.name || "";
  const pluginId = get(action, "pluginId", "");
  const datasourceId = action?.datasource.id || "";
  const plugins = useSelector(getPlugins);
  const dispatch = useDispatch();
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);

  const isWorkflowEditorInitialized = useSelector(
    getIsWorkflowEditorInitialized,
  );

  const settingsConfig = useWorkflowQuerySettings({
    pluginId,
    pluginType: (action?.pluginType as PluginType) || "",
  });

  const onDeleteWorkflowAction = useCallback(() => {
    dispatch(deleteAction({ id: apiId, name: apiName }));
  }, [apiId, apiName]);
  const isDeletePermitted = getHasDeleteActionPermission(
    isFeatureEnabled,
    action?.userPermissions,
  );

  const moreActionsMenu = useMemo(() => {
    return (
      <ActionEditorContextMenu
        isDeletePermitted={isDeletePermitted}
        onDelete={onDeleteWorkflowAction}
      />
    );
  }, []);

  const handleRunClick = useCallback(
    (paginationField?: PaginationField) => {
      const pluginName = plugins.find((plugin) => plugin.id === pluginId)?.name;

      AnalyticsUtil.logEvent("RUN_API_CLICK", {
        apiName,
        apiID: baseApiId,
        datasourceId,
        pluginName: pluginName,
        isMock: false, // as mock db exists only for postgres and mongo plugins
      });
      dispatch(runAction(apiId, paginationField));
    },
    [apiId, baseApiId, apiName, plugins, pluginId, datasourceId],
  );

  const handleDeleteClick = useCallback(() => {
    AnalyticsUtil.logEvent("DELETE_API_CLICK", {
      apiName,
      apiID: baseApiId,
    });
    dispatch(deleteAction({ id: apiId, name: apiName }));
  }, [apiName, baseApiId, apiId]);

  const onSaveWorkflowJSActionName = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      return saveWorkflowActionName(id, name);
    },
    [],
  );

  const isActionRedesignEnabled = useFeatureFlag(
    FEATURE_FLAG.release_actions_redesign_enabled,
  );

  if (isActionRedesignEnabled) {
    return <WorkflowPluginActionEditor />;
  }

  return (
    <ApiEditorContextProvider
      handleDeleteClick={handleDeleteClick}
      handleRunClick={handleRunClick}
      moreActionsMenu={moreActionsMenu}
      saveActionName={onSaveWorkflowJSActionName}
      settingsConfig={settingsConfig}
    >
      <Editor {...props} isEditorInitialized={isWorkflowEditorInitialized} />
    </ApiEditorContextProvider>
  );
}

export default WorkflowApiEditor;
